<!--
 * @Author: dengyi 295477442@qq.com
 * @Date: 2022-03-17 13:51:40
 * @LastEditors: dengyi 295477442@qq.com
 * @LastEditTime: 2022-07-11 14:05:39
 * @FilePath: \tyzsls-website\src\views\Price.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <section class="Price paddingTopHeader">
    <div class="autowidth pc-show">
      <img src="https://static.tyzsls.com/cdn/image/pc-price.png" alt="" />
      <img src="https://static.tyzsls.com/cdn/image/pc-price-2.png" alt="" />
      <div class="btn-box">
        <div class="btn btn1" @click="$router.push({ path: '/apply' })"></div>
        <div class="btn btn2" @click="$router.push({ path: '/apply' })"></div>
        <div class="btn btn3" @click="$router.push({ path: '/apply' })"></div>
        <div class="btn btn4" @click="$router.push({ path: '/apply' })"></div>
      </div>
    </div>
    <div class="autowidth mobile-show">
      <img src="https://static.tyzsls.com/cdn/image/mobile-price.png" alt="" />
      <img src="https://static.tyzsls.com/cdn/image/mobile-price-2.png" alt="" />
      <div class="btn-box" @click="$router.push({ path: '/apply' })"></div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Price'
}
</script>

<style lang="scss" scoped>
.autowidth {
  max-width: 100%;
}
.pc-show {
  position: relative;
  .btn-box {
    position: absolute;
    width: 100%;
    height: 30px;
    left: 0;
    top: 7.1%;
  }
  .btn {
    width: 8%;
    height: 100%;
    cursor: pointer;
  }
  .btn1 {
    position: absolute;
    left: 32.6%;
  }
  .btn2 {
    position: absolute;
    left: 49.4%;
  }
  .btn3 {
    position: absolute;
    left: 66.4%;
  }
  .btn4 {
    position: absolute;
    left: 83.6%;
  }
}
.mobile-show {
  position: relative;
  display: none;
  .btn-box {
    position: absolute;
    width: 80%;
    height: 4%;
    top: 4%;
    right: 0;
    cursor: pointer;
  }
}

@media only screen and (max-width: 640px) {
  .pc-show {
    display: none;
  }
  .mobile-show {
    display: block;
  }
}
</style>
